@font-face {
  font-family: 'Basis Grotesque Regular';
  src: url("../fonts/basis-grotesque-regular-pro-web/web/basis-grotesque-regular-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Basis Grotesque Light';
  src: url("../fonts/basis-grotesque-light-pro-web/web/basis-grotesque-light-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Basis Grotesque Mono Light';
  src: url("../fonts/basis-grotesque-trial/basis-grotesque-mono-light-trial.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Basis Grotesque Medium';
  src: url("../fonts/basis-grotesque-trial/basis-grotesque-medium-trial.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Basis Grotesque Mono Regular';
  src: url("../fonts/basis-grotesque-trial/basis-grotesque-mono-regular-trial.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Basis Grotesque Mono Medium';
  src: url("../fonts/basis-grotesque-trial/basis-grotesque-mono-medium-trial.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Didact Gothic';
  src: url("../fonts/Didact_Gothic/DidactGothic-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Raleway';
  src: url("../fonts/Raleway/Raleway-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Karla';
  src: url("../fonts/Karla/Karla-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Nanum Gothic';
  src: url("../fonts/Nanum_Gothic/NanumGothic-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Quicksand';
  src: url("../fonts/Quicksand/Quicksand-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Work Sans';
  src: url("../fonts/Work_Sans/WorkSans-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto Bold';
  src: url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PT Sans';
  src: url("../fonts/PT_Sans/PTSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Commissioner';
  src: url("../fonts/Commissioner/Commissioner-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/Manrope/Manrope-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Noto Sans';
  src: url("../fonts/Noto_Sans/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Noto Sans Display';
  src: url("../fonts/Noto_Sans_Display/NotoSansDisplay-VariableFont_wdth,wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Outfit';
  src: url("../fonts/Outfit/Outfit-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url("../fonts/Plus_Jakarta_Sans/PlusJakartaSans-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Questrial';
  src: url("../fonts/Questrial/Questrial-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
