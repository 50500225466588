@use "../abstracts/variables" as *;

.participation table {
  border-collapse: collapse;
}
.participation td {
  padding: 10px;
}
.participation-hover-all td:hover {
  color: black;
  background-color: #eee;
}
.participation-color-even tr:nth-child(even){
  background-color: #f2f2f2;
}
.participation-color-odd tr:nth-child(odd){
  background-color: #f2f2f2;
}
.participation-header tr:nth-child(n+2) td:nth-child(n+2):hover {
  color: black;
  background-color: #eee;
}
.participation-header tr:nth-child(1) {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: $pages-green-color;
  color: white;
}
