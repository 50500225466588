@use "../abstracts/variables" as *;


h1 {
  font-family: $header-font-family;
  letter-spacing: -1;
}
h2 {
  font-family: $header-font-family;
  letter-spacing: -1;
}
h3 {
  font-family: $header-font-family;
  letter-spacing: -1;
}
.info-section-body {
  font-family: $primary-font-family;
  color: $info-color;
  line-height: 1.4;
  letter-spacing: 0.2;
  text-rendering: optimizeLegibility;
}
