// Global
$primary-color: #003153;
$secondary-color: #999;
$danger-color: red;
$warning-color: orange;
$success-color: green;
$info-color: #707070;
$neutral-color: #707070;
$green-color: #04AA6D;

$primary-contrast-text: #fff;
$secondary-contrast-text: #000;

// Home


// Pages
$pages-green-color: #76b6a7;