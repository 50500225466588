// transition: link underline
.underline {
  display: inline;
  position: relative;
  overflow: hidden;
}
.underline:after {
  content: '""';
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: -5px;
  background: #000;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.underline:hover:after,
.underline:focus:after,
.underline:active:after {
  left: 0;
  right: auto;
  width: 100%;
}

.test{
  color:red;
  position:relative;
  display:inline-block;
}
.test::before{
      content: '""';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: #fff;
      visibility: "hidden";
      transition: all 0.3s ease-in-out;
}
.test:hover::before{
  visibility: visible;
      width: 100%;
}
